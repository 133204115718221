

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  align-items: center;

  background-color: gray;

  display: flex;
  flex-direction: row;
}


.Gutter {
  background-color: gray;
  height: 100%;
  width: auto;
  flex: 1;
}

.Main {
  background-color: #F0EAD6;
  height: 100%;
  flex: 0 1 700px;
  width: min(100%, 700px);
}

.QuestionCard {
  height: 400px;
  width: 100%;

  display: grid;
  grid-template-rows: 10% 20% 10% 50% 10%;
  grid-template-columns: 5% 90% 5%;

  background-color: #F0EAD6;
}

.Prompt {
  width: 100%;

  font-size: 1.5em;

  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;

  /* We want to text to be centered */
  display: flex;
  justify-content: flex-start;
}

.AnswerInput {
  /* A text input where the user will type their response */

  width: 100%;
  height: 100%;

  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 3;

  background-color: #afa891;

  font-size: 2em;

  resize: none;

  box-sizing: border-box;
  border: none;
  outline: none;
  padding: none;

  
  border-radius: 10px;
  border: 3px solid black;
}


.AnswerInput::placeholder {
  color: #463911;
  font-weight: 200;
}

.WordCounter {
  width: 100%;
  height: 100%;

  grid-row-start: 5;
  grid-row-end: 6;
  grid-column-start: 2;
  grid-column-end: 3;

  display: flex;
  /* right-align the text */  
  justify-content: flex-end;
}

.SubmitButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 500px;
  width: 100%;
}

.SubmitButton {
  /* A big button that the user will click to submit their answers */

  font-size: 3em;
  
  /* round the corners */
  border-radius: 10px;
  border: 3px solid black;

  /* make the button look like a button */
  background-color: #afa891;

  /* change color when the user hovers over the button */
  transition: background-color 0.5s ease;

  cursor: pointer;
}

.SubmitButton:hover {
  background-color: #d6b443;
}

.QuestionCardFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  grid-row-start: 3;
  grid-row-end: 4;

  grid-column-start: 2;
  grid-column-end: 3;
}

.LoadingIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoadingBarContainer {
  width: 100%;  /* Width of the container */
  height: 200px; /* Height of the loading bar */
  background-color: #e0e0e0; /* Color of the background (unfilled part) */
  border-radius: 3px;  /* Optional: round the corners */
  margin-bottom: 10px; /* Space between the bar and the text */

  /* We want to text to be centered */
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 50%;
}

.LoadingBar {
  height: 100%;
  width: 0%; /* Start width, can be set to initial percentage if needed */
  background-color: #007bff; /* Color of the loading progress */
  border-radius: 3px; /* Optional: round the corners to match the container */
  animation: loadingAnimation 2s infinite; /* Animate the loading bar */
}

@keyframes loadingAnimation {
  0% { width: 0%; }
  50% { width: 100%; }
  100% { width: 0%; }
}

.LoadingIndicatorText {
  font-size: 3em;
}

.GradedAnswer {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* apply a subtle border to the answer */
  border: solid black 2px;
  border-radius: 2%;

  /* add a subtle shading to the answer */
  background-color: rgba(0, 0, 0, 0.15);

  
}

.GradedAnswerColumn {

  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}


.GradedAnswerPrompt {
  font-size: 1.5em;
  font-weight: bold;
  width:80%;

  display: flex;
  justify-content: center;
}

.GradedAnswerGrades {
  width: 100%;
  
  display: flex;
  flex-direction: row;
}

.EconGradeColumn {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SocialsGradeColumn {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.EconGrade {
  grid-column-start: 1;
  grid-column-end: 2;

  grid-row-start: 1;
  grid-row-end: 2;
}

.SocialsGrade {
  grid-column-start: 3;
  grid-column-end: 4;

  grid-row-start: 1;
  grid-row-end: 2;
}

.EconExplain {
  grid-column-start: 1;
  grid-column-end: 2;

  grid-row-start: 3;
  grid-row-end: 4;
}

.SocialsExplain {
  grid-column-start: 3;
  grid-column-end: 4;

  grid-row-start: 3;
  grid-row-end: 4;
}

.politicalPlaceGraph {
  width: 100%;
  margin: 0%;
  padding: 0%;
}

.explainer {
  width: 100%;
  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.explainerTitle {
  font-size: 2em;
  font-weight: bold;
  margin: 0%;
  padding: 0%;

  display: flex;
  justify-content: center;

}

.explainerText {
  font-size: 1.25em;
  margin: 0%;
  padding: 0%;
  width:90%;

  display: flex;
  justify-content: center;

}

.spacer {
  height: 5vh;
  width: 100%;
}

.miniSpacer {
  height: 1vh;
  width: 100%;
}

.ClickToReveal{
  justify-self: flex-start;
  align-self: flex-start;

  /* Use a mono-space font so that the text doesn't move when it is revealed */
  font-family: monospace;
  font-size: 1.25em;
}

.ClickToRevealText {
  cursor: pointer;
  background-color: lightblue;

  border: solid black 1px;

  border-radius: 3px;

}

.ClickToRevealText:hover {
  background-color: lightgreen;
}

.ClickToConcealText {
  cursor: pointer;
}

.ClickToConcealText:hover {
  background-color: lightcoral;
}

.ExplainGradeSection {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

.Header {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;


  background-color: #F0EAD6;

  cursor: pointer;
}

.HeaderTitle {
  font-size: 5vh;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderSubtitle {
  font-size: 2vh;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
}

.IntroTextContainer {
  font-size: 1.5em;
  width: 100%;
  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.IntroText {
  width: 90%;
  margin: 0%;
  padding: 0%;
}

.HeaderTitleBox {
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

}

.HeaderLogo {
  height: 5vh;
  border-radius: 50%;
}

.horizontalSpacer {
  width: 2%;
}

.VitalContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.VitalText {
  font-size: 2em;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

}

.Vital {
  width: 100%;
  cursor: pointer;
}

.VitalImage {
  width: 100%;
}

.VitalText {
  width: 100%;
  font-size: 1.5em;
}

.sentimentCollector {
  height: 50px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.sentimentButton {
  height: 100%;
}

.sentimentButton:hover {
  animation: pulse 0.5s infinite;
}

.active {
  filter: brightness(0) saturate(100%) invert(36%) sepia(65%) saturate(4279%) hue-rotate(358deg) brightness(90%) contrast(91%);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.overlay {
  /* takes up the entire screen with a black translucent background */
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 100;
}

.modal {
  /* center of the screen, a square that takes up 50% of the screen */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50vh;
  width: min(90vw, 700px);

  z-index: 101;

  /* rounded edges */
  border-radius: 10px;
  border: 3px solid black;

  background-color: white;

  display: grid;
  grid-template-rows: 5% 20% 5% 40% 5% 20% 5%;
  grid-template-columns: 5% 90% 5%;
}

.modalTitle {
  grid-row-start: 2;
  grid-row-end: 3;

  grid-column-start: 2;
  grid-column-end: 3;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2em;
}

.modalInput {
  grid-row-start: 4;
  grid-row-end: 5;

  grid-column-start: 2;
  grid-column-end: 3;

  resize: none;
}

.modalButtons {
  grid-row-start: 6;
  grid-row-end: 7;

  grid-column-start: 2;
  grid-column-end: 3;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modalButton {
  font-size: 1.5em;
  font-weight: bold;

  border-radius: 10px;
  border: 3px solid black;

  background-color: #afa891;

  transition: background-color 0.5s ease;

  cursor: pointer;
}

.modalButton:hover {
  background-color: #d6b443;
}

.ExplainableText {
  font-weight: bolder;
  background-color: lightblue;
  cursor: pointer;
}

.GraphContainer {
  width: 100%;
}